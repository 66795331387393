// coins
import coinBtcImg from '../assets/images/coin-btc.png';
import coinBnbImg from '../assets/images/coin-bnb.png';
import coinEosImg from '../assets/images/coin-eos.png';
import coinEthImg from '../assets/images/coin-eth.png';
import coinLtcImg from '../assets/images/coin-ltc.png';
import coinMeta1Img from '../assets/images/coin-meta1.png';
import coinXlmImg from '../assets/images/coin-xlm.png';
import coinUsdtImg from '../assets/images/coin-usdt.png';
import coinAdaImg from '../assets/images/coin-ada.png';
import coinBusdImg from '../assets/images/coin-busd.png';
import coinDogeImg from '../assets/images/coin-doge.png';
import coinSolImg from '../assets/images/coin-sol.png';
import coinXmrImg from '../assets/images/coin-xmr.png';
import coinXrpImg from '../assets/images/coin-xrp.png';
import coinTrxImg from '../assets/images/coin-trx.png';

// others
import lastAskImg from '../assets/images/last-ask.png';
import lastBidImg from '../assets/images/last-bid.png';
import priceImg from '../assets/images/price.png';
import volumImg from '../assets/images/btc-volume.png';
import changeImg from '../assets/images/market-cap1.png';

// header images
import logo from '../assets/images/meta-logo.png';
import dayNightImg from '../assets/images/day-night.png';
import helpImg from '../assets/images/help.png';
import enImg from '../assets/images/en.png';
import cnImg from '../assets/images/cn.png';
import esImg from '../assets/images/es.png';

const images = {
  'coin-btc': coinBtcImg,
  'coin-bnb': coinBnbImg,
  'coin-eos': coinEosImg,
  'coin-eth': coinEthImg,
  'coin-ltc': coinLtcImg,
  'coin-meta1': coinMeta1Img,
  'coin-xlm': coinXlmImg,
  'coin-usdt': coinUsdtImg,
  'coin-ada': coinAdaImg,
  'coin-busd': coinBusdImg,
  'coin-doge': coinDogeImg,
  'coin-sol': coinSolImg,
  'coin-xmr': coinXmrImg,
  'coin-xrp': coinXrpImg,
  'coin-trx': coinTrxImg,

  'last-ask': lastAskImg,
  'last-bid': lastBidImg,
  price: priceImg,
  volume: volumImg,
  'change-%': changeImg,

  logo: logo,
  'theme-toggle': dayNightImg,
  'help-mark': helpImg,
  'lang-en': enImg,
  'lang-cn': cnImg,
  'lang-es': esImg,
};

export default images;
