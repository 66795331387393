export const INITIALIZE = 'explorer/INITIALIZE';
export const UNSET = 'explorer/UNSET';

// Operations
export const CLEAR_OPERATIONS = 'explorer/CLEAR_OPERATIONS';
export const LAST_OPERATIONS_FETCH = 'explorer/LAST_OPERATIONS_FETCH';
export const LAST_OPERATIONS_FETCH_SUCCESS =
  'explorer/LAST_OPERATIONS_FETCH_SUCCESS';
export const LAST_OPERATIONS_FETCH_FAILURE =
  'explorer/LAST_OPERATIONS_FETCH_FAILURE';

// Header Info
export const HEADER_FETCH = 'explorer/HEADER_FETCH';
export const HEADER_FETCH_SUCCESS = 'explorer/HEADER_FETCH_SUCCESS';
export const HEADER_FETCH_FAILURE = 'explorer/HEADER_FETCH_FAILURE';

// Last Block
export const LAST_BLOCK_NUMBER_FETCH = 'explorer/LAST_BLOCK_NUMBER_FETCH';
export const LAST_BLOCK_NUMBER_FETCH_SUCCESS =
  'explorer/LAST_BLOCK_NUMBER_FETCH_SUCCESS';
export const LAST_BLOCK_NUMBER_FETCH_FAILURE =
  'explorer/LAST_BLOCK_NUMBER_FETCH_FAILURE';

// Lookup Assets
export const LOOKUP_ASSETS_FETCH = 'explorer/LOOKUP_ASSETS_FETCH';
export const LOOKUP_ASSETS_FETCH_SUCCESS =
  'explorer/LOOKUP_ASSETS_FETCH_SUCCESS';
export const LOOKUP_ASSETS_FETCH_FAILURE =
  'explorer/LOOKUP_ASSETS_FETCH_FAILURE';

// Lookup Accounts
export const LOOKUP_ACCOUNTS_FETCH = 'explorer/LOOKUP_ACCOUNTS_FETCH';
export const LOOKUP_ACCOUNTS_FETCH_SUCCESS =
  'explorer/LOOKUP_ACCOUNTS_FETCH_SUCCESS';
export const LOOKUP_ACCOUNTS_FETCH_FAILURE =
  'explorer/LOOKUP_ACCOUNTS_FETCH_FAILURE';

// Lookup Transactions
export const LOOKUP_TRANSACTIONS_FETCH = 'explorer/LOOKUP_TRANSACTIONS_FETCH';
export const LOOKUP_TRANSACTIONS_FETCH_SUCCESS =
  'explorer/LOOKUP_TRANSACTIONS_FETCH_SUCCESS';
export const LOOKUP_TRANSACTIONS_FETCH_FAILURE =
  'explorer/LOOKUP_TRANSACTIONS_FETCH_FAILURE';

// Big Blocks
export const BIG_BLOCKS_FETCH = 'explorer/BIG_BLOCKS_FETCH';
export const BIG_BLOCKS_FETCH_SUCCESS = 'explorer/BIG_BLOCKS_FETCH_SUCCESS';
export const BIG_BLOCKS_FETCH_FAILURE = 'explorer/BIG_BLOCKS_FETCH_FAILURE';

// Big Transactions
export const BIG_TRANSACTIONS_FETCH = 'explorer/BIG_TRANSACTIONS_FETCH';
export const BIG_TRANSACTIONS_FETCH_SUCCESS =
  'explorer/BIG_TRANSACTIONS_FETCH_SUCCESS';
export const BIG_TRANSACTIONS_FETCH_FAILURE =
  'explorer/BIG_TRANSACTIONS_FETCH_FAILURE';

// Transaction
export const TRANSACTION_FETCH = 'explorer/TRANSACTION_FETCH';
export const TRANSACTION_FETCH_SUCCESS = 'explorer/TRANSACTION_FETCH_SUCCESS';
export const TRANSACTION_FETCH_FAILURE = 'explorer/TRANSACTION_FETCH_FAILURE';

// Active Asssets
export const ACTIVE_ASSETS_FETCH = 'explorer/ACTIVE_ASSETS_FETCH';
export const ACTIVE_ASSETS_FETCH_SUCCESS =
  'explorer/ACTIVE_ASSETS_FETCH_SUCCESS';
export const ACTIVE_ASSETS_FETCH_FAILURE =
  'explorer/ACTIVE_ASSETS_FETCH_FAILURE';

// Dex Volume
export const DEX_VOLUME_FETCH = 'explorer/DEX_VOLUME_FETCH';
export const DEX_VOLUME_FETCH_SUCCESS = 'explorer/DEX_VOLUME_FETCH_SUCCESS';
export const DEX_VOLUME_FETCH_FAILURE = 'explorer/DEX_VOLUME_FETCH_FAILURE';

// Daily Dex Chart
export const DAILY_DEX_CHART_FETCH = 'explorer/DAILY_DEX_CHART_FETCH';
export const DAILY_DEX_CHART_FETCH_SUCCESS =
  'explorer/DAILY_DEX_CHART_FETCH_SUCCESS';
export const DAILY_DEX_CHART_FETCH_FAILURE =
  'explorer/DAILY_DEX_CHART_FETCH_FAILURE';

// Full Asset
export const ASSET_FULL_FETCH = 'explorer/ASSET_FULL_FETCH';
export const ASSET_FULL_FETCH_SUCCESS = 'explorer/ASSET_FULL_FETCH_SUCCESS';
export const ASSET_FULL_FETCH_FAILURE = 'explorer/ASSET_FULL_FETCH_FAILURE';

// Asset Holders
export const ASSET_HOLDERS_FETCH = 'explorer/ASSET_HOLDERS_FETCH';
export const ASSET_HOLDERS_FETCH_SUCCESS =
  'explorer/ASSET_HOLDERS_FETCH_SUCCESS';
export const ASSET_HOLDERS_FETCH_FAILURE =
  'explorer/ASSET_HOLDERS_FETCH_FAILURE';

// Asset Holders Count
export const ASSET_HOLDERS_COUNT_FETCH = 'explorer/ASSET_HOLDERS_COUNT_FETCH';
export const ASSET_HOLDERS_COUNT_FETCH_SUCCESS =
  'explorer/ASSET_HOLDERS_COUNT_FETCH_SUCCESS';
export const ASSET_HOLDERS_COUNT_FETCH_FAILURE =
  'explorer/ASSET_HOLDERS_COUNT_FETCH_FAILURE';

// Asset Markets
export const ASSET_MARKETS_FETCH = 'explorer/ASSET_MARKETS_FETCH';
export const ASSET_MARKETS_FETCH_SUCCESS =
  'explorer/ASSET_MARKETS_FETCH_SUCCESS';
export const ASSET_MARKETS_FETCH_FAILURE =
  'explorer/ASSET_MARKETS_FETCH_FAILURE';

// Most Active Markets
export const ACTIVE_MARKETS_FETCH = 'explorer/ACTIVE_MARKETS_FETCH';
export const ACTIVE_MARKETS_FETCH_SUCCESS =
  'explorer/ACTIVE_MARKETS_FETCH_SUCCESS';
export const ACTIVE_MARKETS_FETCH_FAILURE =
  'explorer/ACTIVE_MARKETS_FETCH_FAILURE';

// Ticker
export const TICKER_FETCH = 'explorer/TICKER_FETCH';
export const TICKER_FETCH_SUCCESS = 'explorer/TICKER_FETCH_SUCCESS';
export const TICKER_FETCH_FAILURE = 'explorer/TICKER_FETCH_FAILURE';

// Fees
export const FEES_FETCH = 'explorer/FEES_FETCH';
export const FEES_FETCH_SUCCESS = 'explorer/FEES_FETCH_SUCCESS';
export const FEES_FETCH_FAILURE = 'explorer/FEES_FETCH_FAILURE';

// Committe Members
export const COMMITTEE_MEMBERS_FETCH = 'explorer/COMMITTEE_MEMBERS_FETCH';
export const COMMITTEE_MEMBERS_FETCH_SUCCESS =
  'explorer/COMMITTEE_MEMBERS_FETCH_SUCCESS';
export const COMMITTEE_MEMBERS_FETCH_FAILURE =
  'explorer/COMMITTEE_MEMBERS_FETCH_FAILURE';

// Witness
export const WITNESSES_FETCH = 'explorer/WITNESSES_FETCH';
export const WITNESSES_FETCH_SUCCESS = 'explorer/WITNESSES_FETCH_SUCCESS';
export const WITNESSES_FETCH_FAILURE = 'explorer/WITNESSES_FETCH_FAILURE';

// account history
export const ACCOUNT_HISTORY_FETCH = 'explorer/ACCOUNT_HISTORY_FETCH';
export const ACCOUNT_HISTORY_FETCH_SUCCESS =
  'explorer/ACCOUNT_HISTORY_FETCH_SUCCESS';

// set pie data
export const SET_PIE_DATA = 'explorer/SET_PIE_DATA';

export const FETCH_SYSTEM_ACCOUNT_BALANCES =
  'explorer/FETCH_SYSTEM_ACCOUNT_BALANCES';
export const FETCH_SYSTEM_ACCOUNT_BALANCES_SUCCESS =
  'explorer/FETCH_SYSTEM_ACCOUNT_BALANCES_SUCCESS';
export const FETCH_SYSTEM_ACCOUNT_BALANCES_FAILURE =
  'explorer/FETCH_SYSTEM_ACCOUNT_BALANCES_FAILURE';
